exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[id]-js": () => import("./../../../src/pages/blog/[id].js" /* webpackChunkName: "component---src-pages-blog-[id]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-guides-[id]-js": () => import("./../../../src/pages/guides/[id].js" /* webpackChunkName: "component---src-pages-guides-[id]-js" */),
  "component---src-pages-guides-index-js": () => import("./../../../src/pages/guides/index.js" /* webpackChunkName: "component---src-pages-guides-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markets-js": () => import("./../../../src/pages/markets.js" /* webpackChunkName: "component---src-pages-markets-js" */),
  "component---src-pages-vaults-js": () => import("./../../../src/pages/vaults.js" /* webpackChunkName: "component---src-pages-vaults-js" */)
}

